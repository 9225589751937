<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header class="mb-3">
            Product categories
            <template slot="actions">
                <mercur-button class="btn btn-raised btn-yellow text-uppercase" :to="{
                    name: 'ProductContentCategoryAdd'
                }">Add category</mercur-button>
            </template>
        </grid-header>
        <data-table
            class="height-100 border"
            :options="gridOptions"
            :items="categories"
        ></data-table>
    </mercur-card>
</template>

<script>
import DataTable from '../../../components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '../../../components/GridHeader'

export default {
    name: 'ProductContentCategoriesOverview',
    components: { GridHeader, DataTable },
    data () {
        return {
            gridUrl: CONFIG.API.ROUTES.PRODUCT_CONTENT.CATEGORIES.LIST,
            gridOptions: {
                columns: {
                    'Category': {
                        field: 'categoryName',
                    },
                },
                actions: [
                    {
                        text: 'Edit',
                        icon: 'fas fa-edit',
                        to: ({ data }) => {
                            return {
                                name: 'ProductContentCategoryDetail',
                                params: {
                                    categoryId: data.productCategoryId,
                                },
                            }
                        },
                        disabled: () => {
                            return this.loading
                        },
                    },
                ],
            },
        }
    },
    computed: {
        categories () {
            return this.$store.state.productContent.categories
        },
    },
}
</script>
